<div class="select-area flex-column" *ngIf="companyList.length != 0">
  <div class="d-flex align-items-center w-100">
    <div *ngIf="profile_image?.charAt(0) == '#'" [ngStyle]="{ 'background-color': profile_image }" class="avatar">
      {{ avatar }}
    </div>
    <div *ngIf="profile_image?.charAt(0) !== '#'">
      <img style="border-radius: 28%" alt="icon" draggable="false" width="50" height="50" src="{{ profile_image }}" />
    </div>
    <div class="select-field">
      <mat-form-field appearance="fill">
        <mat-select (selectionChange)="selectedValue($event.value)" [value]="selectedCompany">
          <mat-option *ngFor="let company of companyList" [value]="company.company_name" class="multiline-mat-option">
            {{ company.company_name | titlecase }}
          </mat-option>
        </mat-select>
        <img src="./assets/icons/select-icon.svg" alt="icon" draggable="false" />
      </mat-form-field>
      <p class="count-members">{{ checkMember() }}</p>
    </div>
  </div>
  <div class="divider-hr"></div>
</div>
<ul class="sidebar-menu-section list-unstyled">
  <ng-container *ngFor="let submenu of sideMenu; let i = index">
    <li *ngIf="submenu.label !== 'HelpSection' && submenu.label != 'Time sheet' && submenu.label != 'Leave'"
      (click)="submenu.label === 'Logout' ? authService.logout() : ''" (isActiveChange)="changeRoute($event)"
      [routerLink]="submenu.route" [routerLinkActive]="
        getPath().includes(submenu.label) ? 'active-link' : ''
      ">
      <a class="nav-link" [ngClass]="activeClass(submenu.route)">
        <img alt="icon" [src]="getImage(submenu.label)" draggable="false" />
        <label>{{ submenu.label }}</label>
      </a>
    </li>
    <li *ngIf="submenu.label == 'Time sheet' && timeLogData?.is_enabled"
      (click)="submenu.label === 'Logout' ? authService.logout() : ''" (isActiveChange)="changeRoute($event)"
      [routerLink]="submenu.route" [routerLinkActive]="
        getPath().includes(submenu.label) ? 'active-link' : ''
      ">
      <a class="nav-link" [ngClass]="activeClass(submenu.route)">
        <img alt="icon" [src]="getImage(submenu.label)" draggable="false" />
        <label>{{ submenu.label }}</label>
      </a>
    </li>

    <li *ngIf="submenu.label == 'Leave' && timeLogData?.is_hrms_enabled"
      (click)="submenu.label === 'Logout' ? authService.logout() : ''" (isActiveChange)="changeRoute($event)"
      [routerLink]="submenu.route" [routerLinkActive]="
        getPath().includes(submenu.label) ? 'active-link' : ''
      ">
      <a class="nav-link" [ngClass]="activeClass(submenu.route)">
        <img alt="icon" [src]="getImage(submenu.label)" draggable="false" />
        <label>{{ submenu.label }}</label>
      </a>
    </li>

    <li class="h-s-list d-none" *ngIf="submenu.label === 'HelpSection'">
      <a href="https://www.taskopad.com/terms-of-use/" style="text-decoration: none">
        <div class="help-section">
          <h2>Learn & Get Inspired</h2>
          <p class="help-desc">
            Dive right in and get started with the top must-have
          </p>
          <p class="help-start">LET’S START</p>
        </div>
      </a>
    </li>
  </ng-container>
</ul>